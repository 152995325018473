import React from 'react';
import '../../App.css';

import ContactCards from '../ContactCards';
import Footer from '../Footer';

function Contact() {
  return (
    <>
      <ContactCards />
      <Footer />
    </>
  );
}

export default Contact;