import React from 'react';
import './ServicesCards.css';

function ServicesCards() {
  return (
    <div className='services-cards'>
      <h1>
        Areas of Practice
      </h1>
      <div className='services-cards-wrapper'>
        <div className='services-cards-items'>
          <h3>IDENTIFY THE “APPROPRIATE CONTENT” FOR ALL REGULATORY SUBMISSIONS</h3>
            <ul className='expertise'>
            <li>
              Marketing application (NDA/MAA/NDS)
            </li>
            <li>
              Type II Drug Master Files (DMF)
            </li>
            <li>
              Change management
              <ul style={{ paddingInlineStart: "25px" }}>
                <li>
                  Post-approval stages
                </li>
              </ul>
            </li>
            </ul>
        </div>
        <div className='services-cards-items'>
          <h3>CMC SUBMISSION PROJECT MANAGEMENT</h3>
            <ul className='expertise'>
              <li>
                Global CMC strategy
              </li>
              <li>
                Pant network strategy
              </li>
            </ul>
        </div>
        <div className='services-cards-items'>
          <h3>REGULATORY SUBMISSIONS</h3>
            <ul className='expertise'>
            <li>
              New registration
            </li>
            <li>
              Product lifecycle management
            </li>
            <li>
              Second brand submission
            </li>
            </ul>
        </div>
      </div>
      <div className='services-cards-wrapper'>
        <div className='services-cards-items'>
          <h3>RESPONSES TO REGULATORY AGENCY INQUIRIES</h3>
            <ul className='expertise'>
              <li>
                Responses to regulatory agencies queries.
              </li>
            </ul>
        </div>
        <div className='services-cards-items'>
          <h3>CTD DOCUMENTATION (MODULE 3/ MODULE 2.3 QOS)</h3>
            <ul className='expertise'>
              <li>
                eCTD /CTD documentation submissions
              </li>
              <li>
                Marketing applications (NDA/MAA/NDS)
              </li>
              <li>
                sNDS Post approval Type II or Do & Tell, PAS or CBE, Notifications/ Variations
              </li>
              <li>
                Type II Drug Master Files (DMF)
              </li>
              <li>
                EU Certificate of Suitability (CEP) for multiple products/licenses
              </li>
              <li>
                Legal entity name change (LENC) for multiple products/licenses
              </li>
            </ul>
        </div>
        <div className='services-cards-items'>
          <h3>CMC REGULATORY CONFORMANCE GAP ANALYSIS AND REMEDIATION</h3>
            <ul className='expertise'>
              <li>
                Gap Analysis of CMC dossier
              </li>
              <li>
                Alignment of CMC dossier with site practice
              </li>
              <li>
                Alignment of product specifications with current 
              </li>
              <li>
                Dossier harmonization
              </li>
              <li>
                Dossier Contemporized to CTD or eCTD  
              </li>
            </ul>
        </div>
      </div>
      <div className='services-cards-wrapper'>
        <div className='services-cards-items'>
          <h3>LIAISON SERVICES WITH REGULATORY AGENCIESc</h3>
            <ul className='expertise'>
              <li>
                Represent your company in scheduled meeting with regulatory agencies
              </li>
            </ul>
        </div>
        <div className='services-cards-items'>
          <h3>CMC ANNUAL PRODUCT REVIEW</h3>
            <ul className='expertise'>
              <li>
                Annual Report Documentation
                <ul style={{ paddingInlineStart: "25px" }}>
                  <li>
                    NDAs
                  </li>
                  <li>
                    DMFs.
                  </li>
                </ul>
              </li>
            </ul>
        </div>
        <div className='services-cards-items'>
          <h3>CMC REGULATORY DUE-DILIGENCE</h3>
            <ul className='expertise'>
            <li>
              CMC Report writing (Development, Stability, Validation)
            </li>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default ServicesCards;
