/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import Pharmalucent from "./../assets/Pharmalucent-image.png";

function Footer() {
  return (
    <div className='footer-container'>
      <p className='footer-heading'>
        International Regulatory CMC Consulting Services
      </p>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <ul style={{ listStyleType: "none" }}>
              <li>Pharmalucent LLC </li>
              <li>Provides Strategic </li>
              <li>Chemistry, Manufacturing </li>
              <li>and Controls (CMC) </li>
              <li>Regulatory Sciences </li>
              <li>Consulting Services to the  </li>
              <li>Biopharmaceutical and  </li>
              <li>Pharmaceutical Industries. </li>
            </ul>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <ul style={{ listStyleType: "none" }}>
              <li>☎   (978) 761-2631</li>
              <li><i className="fa fa-envelope"></i> <a href='mailto:info@pharmalucentcmc.com'>   info@pharmalucentcmc.com</a></li>
              <li><i className="fa fa-map-marker"></i>  Boston, Massachussets </li>
              <li> </li>
              <li> </li>
            </ul>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <img style={{ width: "150px" }} src={Pharmalucent} />
            </Link>
          </div>
          <small className='website-rights'>Pharmalucent © 2022 | disclaimer | Regulatory CMC Consulting | Services</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to={{pathname: 'https://mobile.twitter.com/pharmalucent/status/1476360218871312386'}}
              target='_blank'
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </Link>
            <Link
              className='social-icon-link twitter'
              to={{pathname: 'https://www.linkedin.com/in/pharmalucent-llc-668879197/'}}
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
