import React from 'react';
import './ContactCards.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ContactCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone1: '',
      phone2: '',
      phone3: '',
      options: [],
      help: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event, type) {
    switch (type) {
      case "firstName":
        this.setState({firstName: event.target.value});
        break;
      case "lastName":
        this.setState({lastName: event.target.value});
        break;
      case "email":
        this.setState({email: event.target.value});
        break;
      case "phone1":
        this.setState({phone1: event.target.value});
        break;
      case "phone2":
        this.setState({phone2: event.target.value});
        break;
      case "phone3":
        this.setState({phone3: event.target.value});
        break;
      case "options":
        let options = this.state.options;
        if (event.target.checked) {
          options.push(event.target.value);
        } else {
          options = options.filter(item => item !== event.target.value);
        }
        this.setState({options: options});
        break;
      case "help":
        this.setState({help: event.target.value});
        break;
      default:
        break;
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    var disableSubmitButton = this.disableSubmitButton();
    if (disableSubmitButton) {
      toast.warning("Please fill in all required fields",{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      try {
        await emailjs.sendForm('service_top703p', 'template_ge8ldd8', event.target, 'eyz_u3l7xFb_tflb9');
        toast.success("Message Sent, We will get back to you shortly",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(function(){ window.location.reload(); }, 5000);
      } catch (error) { 
        toast.error("An error occurred, Please try again",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }

  disableSubmitButton() {
    if ((this.state.firstName && this.state.firstName !== "") &&
      (this.state.lastName && this.state.lastName !== "") &&
      (this.state.email && this.state.email !== "") &&
      (this.state.phone1 && this.state.phone1 !== "") &&
      (this.state.phone2 && this.state.phone2 !== "") &&
      (this.state.phone3 && this.state.phone3 !== "") &&
      (this.state.options && this.state.options.length > 0) &&
      (this.state.help && this.state.help !== "")
    ) {
      return false;
    } else {
      return true;
    }
  }

  render () {
    var disableSubmitButton = this.disableSubmitButton();
    return(
      <div className='contact-cards'>
        <div className='contact-cards-wrapper'>
          <div className='contact-cards-items'  style={{ flex: 2 }}>
            <h1>
              Contact us for rates
            </h1>
            <p>
              Depending on the project, we offer an hourly rate or a flat rate. Our scientists keep detailed logs of the time they spend on your projects. Work logs will be disclosed to all clients upon the completion of a project. We only work for as long as a project requires, so you’ll never be overbilled for unproductive time. 
            </p>
            <h1>
          Book an appointment
        </h1>
        <p>
          Use the form below to tell us about your Regulatory CMC inquiry, and we’ll call you back to schedule an appointment. Please be as detailed as possible. Include what type of services along with any specific details you would like to share such as therapeutic areas, type of product (small or large molecule), national or international submission. To help us best serve your inquiry, we recommend that you first describe the issue you’re having before telling us what you want to achieve. You may also email or call us to make an appointment. Our general response time is one business day.
        </p>
        <div className='form-wrapper'>
          <div className='form-inner-wrapper'>
            <form onSubmit={this.handleSubmit}>
              <div className="field-list clear">
                <fieldset id="name-yui_3_17_2_1_1463758909823_37180" className="form-item fields name required">
                  <legend className="title">
                    Name
                    <span className="required" aria-hidden="true">*</span>
                  </legend>
                  <div className="field first-name">
                    <label className="caption">
                      <input value={this.state.firstName} onChange={(event) => this.handleChange(event, "firstName")} className="field-element field-control" name="fname" x-autocompletetype="given-name" type="text" maxLength="30" data-title="First" aria-required="true" />
                      <span className="caption-text">First Name</span>
                    </label>
                  </div>
                  <div className="field last-name">
                    <label className="caption">
                      <input value={this.state.lastName} onChange={(event) => this.handleChange(event, "lastName")} className="field-element field-control" name="lname" x-autocompletetype="surname" type="text" maxLength="30" data-title="Last" aria-required="true" />
                      <span className="caption-text">Last Name</span>
                    </label>
                  </div>
                </fieldset>
                <div id="email-yui_3_17_2_1_1463758909823_37519" className="form-item field email required">
                  <label className="title" htmlFor="email-yui_3_17_2_1_1463758909823_37519-field">
                    Email Address
                      <span className="required" aria-hidden="true">*</span>
                  </label>
                  <input value={this.state.email} onChange={(event) => this.handleChange(event, "email")} className="field-element" id="email-yui_3_17_2_1_1463758909823_37519-field" name="email" type="email" autoComplete="email" aria-required="true"/>
                </div>
                <fieldset id="phone-yui_3_17_2_1_1464038823631_299530" className="form-item fields phone required">
                <legend className="title">
                  Phone
                    <span className="required" aria-hidden="true">*</span>
                </legend> 
                <div className="field text three-digits">
                  <label className="caption">
                    <input value={this.state.phone1} onChange={(event) => this.handleChange(event, "phone1")} className="field-element" x-autocompletetype="phone-area-code" name="phone1" type="text" maxLength="3" data-title="Areacode" aria-required="true"/>
                    <span className="caption-text">(###)</span>
                  </label>
                </div>
                <div className="field text three-digits">
                  <label className="caption">
                    <input value={this.state.phone2} onChange={(event) => this.handleChange(event, "phone2")} className="field-element" x-autocompletetype="phone-local-prefix" name="phone2" type="text" maxLength="3" data-title="Prefix" aria-required="true"/>
                    <span className="caption-text">###</span>
                  </label>
                </div>
                <div className="field text four-digits">
                  <label className="caption">
                    <input value={this.state.phone3} onChange={(event) => this.handleChange(event, "phone3")} className="field-element" x-autocompletetype="phone-local-suffix" name="phone3" type="text" maxLength="4" data-title="Line" aria-required="true"/>
                    <span className="caption-text">####</span>
                  </label>
                </div>
                </fieldset>
                <fieldset id="checkbox-yui_3_17_2_1_1467126224954_62662" className="form-item field checkbox required" aria-required="true">
                  <legend className="title">
                    Reason of Inquiry
                      <span className="required" aria-hidden="true">*</span>  
                  </legend>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="Regulatory Strategy" /> Regulatory Strategy</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="Identify the “Appropriate Content” for all regulatory submissions" /> Identify the “Appropriate Content” for all regulatory submissions</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="Change management" /> Change management</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="CMC submission Project Management" /> CMC submission Project Management</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="Regulatory submissions" /> Regulatory submissions</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="Responses to Regulatory Agency inquiries" /> Responses to Regulatory Agency inquiries</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="eCTD Documentation (Module 3/ Module 2.3 QOS)" /> eCTD Documentation (Module 3/ Module 2.3 QOS)</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="CMC Regulatory conformance Gap Analysis and Remediation" /> CMC Regulatory conformance Gap Analysis and Remediation</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="CMC Regulatory due-diligence" /> CMC Regulatory due-diligence</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="Liaison services with regulatory Agencies" /> Liaison services with regulatory Agencies</label></div>
                  <div className="option"><label className='option-checkbox'><input type="checkbox" name="reasonOfInquiry" onChange={(event) => this.handleChange(event, "options")} value="Other" /> Other</label></div>
                </fieldset>
                <div id="textarea-yui_3_17_2_1_1463758909823_38375" className="form-item field textarea required">
                  <label className="title" htmlFor="textarea-yui_3_17_2_1_1463758909823_38375-field">
                    How Can We Help?
                      <span className="required" aria-hidden="true">*</span>
                  </label>
                  <div className="description">Please do not include confidential or sensitive information in your message. </div>
                  <textarea className="field-element " id="textarea-yui_3_17_2_1_1463758909823_38375-field" name="help" aria-required="true" onChange={(event) => this.handleChange(event, "help")}></textarea>
                </div>
              </div>
              <div data-animation-role="button" className="form-button-wrapper form-button-wrapper--align-left">
                <input className={`button sqs-system-button sqs-editable-button sqs-button-element--primary + ${disableSubmitButton ? " disabled-submit-button" : " enabled-submit-button"}`} disabled={this.state.disableSubmitButton ? "disabled" : ""} name="help" type="submit" value="Submit"/>
              </div>
            </form>  
          </div>
        </div>
          </div>
          <div className='contact-cards-items' style={{ flex: 1 }}>
            <ul className='contact'>
              <h3>Location</h3>
              <li>
                Boston, Massachussets
              </li>
            </ul>
            <ul className='contact'>
              <h3>Office hours</h3>
              <li>
                Monday - Friday 9AM - 6PM
              </li>
            </ul>
            <ul className='contact'>
              <h3>Contact</h3>
              <li>☎   (978) 761-2631</li>
              <li><i className="fa fa-envelope"></i> <a href='mailto:info@pharmalucentcmc.com'>   info@pharmalucentcmc.com</a></li>
            </ul>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    )
  };
}

export default ContactCards;
