import React from 'react';
import './Paragraphs.css';

function Paragraphs() {
  return (
    <div className='paragraphs'>
      <h1>Providing Strategic Regulatory CMC Consulting Services</h1>
      <div className='paragraphs__container'>
        <div className='paragraphs__wrapper'>
          <ul className='paragraphs__items'>
            <p>
              Headquartered in Massachusetts, Pharmalucent LLC provides strategic Chemistry, Manufacturing and Controls (CMC) regulatory sciences consulting services to the biopharmaceutical and pharmaceutical industries. 
            </p>
          </ul>
          <ul className='paragraphs__items'>
            <p>
              A forthcoming, robust and adaptive CMC regulatory strategy is essential when dealing with international regulatory authorities (FDA, EMA) interactions or meetings, submissions, conformance or compliance. Pharmalucent, LLC provides expert CMC consulting services to assure that investigational, approved/marketed pharmaceutical products adhere to regulations requirements. 
            </p>
          </ul>
          <ul className='paragraphs__items'>
            <p>
             Our company specializes in all regulatory CMC submission matters, from the seed of an idea to the final product. We bring extensive experience and professionalism to every regulatory CMC submission and customize our support to your individual needs and concerns.
            </p>
          </ul>
          <ul className='paragraphs__items'>
            <p>
             Our scientists have been in the industry for over a decade working as regulatory CMC author strategists dealing with global regulatory CMC activities. We’re on the pulse of reformation of the regulatory framework following the International Council for Harmonization (ICH), and monitoring the ever changing regulatory landscape with the introduction of Common Technical Document (CTD) and eCTD to the industry, to inform our own scientific approaches—all to give you exceptional advice that will last for years to come.
            </p>
          </ul>
          <ul className='paragraphs__items'>
            <p>
            Get in touch with us to set up a consultation, or use the contact form to enquire whether our services are right for you.
            </p>
          </ul>
          
        </div>
      </div>
    </div>
  );
}

export default Paragraphs;
