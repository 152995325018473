import React from 'react';
import '../../App.css';
import ServicesCards from '../ServicesCards';
import Footer from '../Footer';

function Services() {
  return (
    <>
      <ServicesCards />
      <Footer />
    </>
  );
}

export default Services;
