import React from 'react';
import './HomeCards.css';

function HomeCards() {
  return (
    <div className='home-cards'>
      <h1>
        Areas of Expertise
      </h1>
      <div className='home-cards-wrapper'>
        <div className='home-cards-items'>
          <h3>STRATEGIC REGULATORY SUBMISSIONS SERVICES</h3>
            <p>Identify the “Appropriate Content” for all regulatory submissions</p>
            <ul className='expertise'>
            <li>
              Marketing application (NDA/MAA/NDS)
            </li>
            <li>
              Type II Drug Master Files (DMF)
            </li>
            <li>
              Change management
            </li>
            <li>
              CMC submission Project Management
            </li>
            <li>
              Regulatory submissions
            </li>
            <li>
              Responses to Regulatory Agency inquiries
            </li>
            <li>
              eCTD Documentation (Module 3/ Module 2.3 QOS)
            </li>
            </ul>
        </div>
        <div className='home-cards-items'>
          <h3>STRATEGIC REGULATORY CONFORMANCE/ COMPLIANCE SERVICES</h3>
            <p>CMC Regulatory conformance Gap Analysis and Remediation</p>
            <ul className='expertise'>
            <li>
              CMC Regulatory due-diligence
            </li>
            <li>
              Liaison services with regulatory Agencies
            </li>
            <li>
              CMC Annual Product Review
            </li>
            <li>
              CMC submission Project Management
            </li>
            <li>
              CMC Report writing (Development, Stability, Validation)
            </li>
            <li>
              Annual Report Documentation
            </li>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default HomeCards;
