import React from 'react';
import '../../App.css';
import Paragraphs from '../Paragraphs';
import Footer from '../Footer';
import HomeCards from '../HomeCards';

function Home() {
  return (
    <>
      <Paragraphs />
      <HomeCards />
      <Footer />
    </>
  );
}

export default Home;
